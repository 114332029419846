// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-theme-style-guide-src-template-js": () => import("../../../node_modules/gatsby-theme-style-guide/src/template.js" /* webpackChunkName: "component---node-modules-gatsby-theme-style-guide-src-template-js" */),
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-playground-tsx": () => import("../src/pages/playground.tsx" /* webpackChunkName: "component---src-pages-playground-tsx" */),
  "component---src-pages-index-mdx": () => import("../src/pages/index.mdx" /* webpackChunkName: "component---src-pages-index-mdx" */),
  "component---src-pages-spec-mdx": () => import("../src/pages/spec.mdx" /* webpackChunkName: "component---src-pages-spec-mdx" */)
}

